<template>
  <v-container
    fluid
    class="pt-0"
  >
    <v-row>
      <v-col cols="12">
        <details-toolbar
          :title="$t('acquisition.received.detail.cardInfoTitle')"
          :detail-data="detailData"
        >
          <template v-slot:chip>
            <status-chip :status="detailData.status" />
          </template>
        </details-toolbar>
        <card-layout
          enable-actions
          disable-title
        >
          <template v-slot:content>
            <received-detail-overview :detail-data="detailData" />
          </template>
          <template v-slot:actions>
            <v-btn
              color="primary"
              text
              rounded
              small
              target="_blank"
              :disabled="!detailData.url"
              :href="detailData.url"
            >
              <v-icon left>
                {{ $vuetify.icons.values.download }}
              </v-icon>
              {{ $t('acquisition.received.detail.downloadLink') }}
            </v-btn>
          </template>
        </card-layout>
      </v-col>
      <!-- <v-col cols="12">
        <card-layout>
          <template v-slot:title>
            {{ $t('general.documents') }}
          </template>
          <template v-slot:content>
            <received-documents-list
              v-if="detailData.id"
              :detail-data="detailData"
            />
          </template>
        </card-layout>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import receivedApi from '@/api/modules/received'
import { getDateTimeValue } from '@/mixins/dates'
import DetailsToolbar from '@/components/toolbars/DetailsToolbar'
import CardLayout from '@/components/layout/CardLayout'
import StatusChip from '@/components/chips/StatusChip'
import ReceivedDetailOverview from '@/components/details/received/Overview'
// import ReceivedDocumentsList from '@/components/details/received/DocumentsList'

export default {
  name: 'ReceivedDetails',
  components: {
    DetailsToolbar,
    CardLayout,
    StatusChip,
    ReceivedDetailOverview
    // ReceivedDocumentsList
  },
  mixins: [
    getDateTimeValue
  ],
  data () {
    return {
      detailData: {}
    }
  },
  mounted () {
    this.getDetailData()
  },
  methods: {
    async getDetailData () {
      this.loadingData = true
      const result = await receivedApi.getReceivedFilesDetail()
      this.detailData = result
      this.loadingData = false
    },
    getItemColor (item) {
      let color = 'success'

      if (item.warning) color = 'warning'
      if (item.error) color = 'error'

      return color
    },
    getItemIcon (item) {
      let icon = 'done'

      if (item.warning) icon = 'warning'
      if (item.error) icon = 'error_outline'

      return icon
    }
  }
}
</script>
