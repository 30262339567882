<template>
  <v-navigation-drawer
    v-model="sideSheetState"
    fixed
    floating
    temporary
    hide-overlay
    right
    :width="sideSheetWidth"
  >
    <global-events
      @keyup.esc="sideSheetState = false"
    />
    <template v-slot:prepend>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-btn
            icon
            @click="sideSheetState = false"
          >
            <v-icon>
              {{ $vuetify.icons.values.close }}
            </v-icon>
          </v-btn>
          <slot name="title">
            {{ $t('filters.generic') }}
          </slot>
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <v-card flat>
      <v-card-text>
        <slot name="content" />
      </v-card-text>
    </v-card>
    <template v-slot:append>
      <simple-sheet-bottom-toolbar
        :loading-data="loadingData"
        @close-filters="$emit('update:expand-sheet', false)"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import SimpleSheetBottomToolbar from '@/components/toolbars/SimpleSheetBottomToolbar'
import GlobalEvents from 'vue-global-events'

export default {
  name: 'SimpleSideSheet',
  components: {
    SimpleSheetBottomToolbar,
    GlobalEvents
  },
  props: {
    expandSheet: { type: Boolean, default: false },
    loadingData: { type: Boolean, default: false }
  },
  computed: {
    sideSheetState: {
      get () {
        return this.expandSheet
      },
      set (value) {
        this.$emit('update:expand-sheet', value)
      }
    },
    sideSheetWidth () {
      return this.$vuetify.breakpoint.mdAndUp
        ? '50%'
        : '100%'
    }
  }
}
</script>
