import { apiHttpService } from '@/api'
import getNested from 'get-nested'
import { getWorkspaceId, getRouteDetailId } from '@/api/utils/route_params'
import { arraySerializer } from '@/api/utils/serializer'
import { setPaginationParams } from '@/api/utils/pagination_params'
import {
  getISOStringDateTimeGte,
  getISOStringDateTimeLte
} from '@/api/utils/iso_date'

const mapResponse = response => {
  return {
    id: response.id,
    acquisitionPipeline: getNested(() => response.pipeline.name, ' '),
    name: response.received_file_name,
    createdAt: response.completed_at,
    compDate: response.completed_at,
    status: response.status,
    url: response.received_file
  }
}

const processResponseList = results => results.map(result => {
  return mapResponse(result)
})

export default {
  async getReceivedFilesDetail () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/received-files/' + getRouteDetailId(),
        method: 'GET',
        transformResponse: [data => {
          return mapResponse(data)
        }]
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getReceivedFilesList (queryParams) {
    let params = {}

    if (queryParams.activeFilters) {
      params = {
        id: queryParams.activeFilters.id,
        pipeline_ids: queryParams.activeFilters.acquisitionPipeline,
        received_file_name: queryParams.activeFilters.name,
        created_at_gte: getISOStringDateTimeGte(queryParams.activeFilters.createdAtFrom),
        created_at_lte: getISOStringDateTimeLte(queryParams.activeFilters.createdAtTo),
        completed_at_gte: getISOStringDateTimeGte(queryParams.activeFilters.compDateFrom),
        completed_at_lte: getISOStringDateTimeLte(queryParams.activeFilters.compDateTo),
        status: queryParams.activeFilters.status
      }
    }

    setPaginationParams(queryParams, params, 'received')

    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/received-files/',
        method: 'GET',
        params: arraySerializer(params)
      })
      const processedResponse = {
        itemCount: response.data.count,
        pageCount: response.data.total_pages,
        items: processResponseList(response.data.results)
      }
      return Promise.resolve(processedResponse)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
