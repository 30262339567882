<template>
  <v-row>
    <v-col cols="12">
      <title-and-content-row :title="$t('general.details')">
        <template v-slot:content>
          <div>
            <strong>{{ detailData.name }}</strong>
            <div class="text-caption grey--text text--darken-2">
              {{ $t('acquisition.received.createdAt') }}:
              {{ getDateTimeValue(detailData.createdAt, 'long') }}
            </div>
          </div>
        </template>
        <template v-slot:action>
          <v-btn
            outlined
            small
            rounded
            :input-value="expandHistory"
            :disabled="!detailData.id"
            active-class="primary--text"
            @click="expandHistory = !expandHistory"
          >
            {{ $t('integration.detail.process') }}
          </v-btn>
        </template>
      </title-and-content-row>
      <v-divider />
      <title-and-content-row
        :title="$t('acquisition.received.acquisitionPipeline')"
        :content="detailData.acquisitionPipeline"
      />
      <v-divider />
      <title-and-content-row
        :title="$t('acquisition.received.compDate')"
        :content="getDateTimeValue(detailData.compDate, 'long')"
      />
      <simple-side-sheet
        v-if="detailData.id"
        :expand-sheet.sync="expandHistory"
      >
        <template v-slot:title>
          {{ $t('integration.detail.process') }}
        </template>
        <template v-slot:content>
          <message-history :params="messagesParams" />
        </template>
      </simple-side-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { getDateTimeValue } from '@/mixins/dates'
import TitleAndContentRow from '@/components/layout/TitleAndContentRow'
import SimpleSideSheet from '@/components/side_sheets/SimpleSideSheet'
import MessageHistory from '@/components/messages/MessageHistory'

export default {
  name: 'ReceivedDetailOverview',
  components: {
    TitleAndContentRow,
    SimpleSideSheet,
    MessageHistory
  },
  mixins: [
    getDateTimeValue
  ],
  props: {
    detailData: { type: Object, default: () => { return {} } }
  },
  data () {
    return {
      expandHistory: false
    }
  },
  computed: {
    processingChipColor () {
      return this.detailData.processingStatus === 'complete' ? 'success--text' : 'grey--text'
    },
    processingChipIcon () {
      return this.detailData.processingStatus === 'complete'
        ? this.$vuetify.icons.values.check
        : this.$vuetify.icons.values.loading
    },
    messagesParams () {
      return this.detailData
        ? [
          { id: this.detailData.id, content: 'receivedFile' }
        ]
        : []
    }
  }
}
</script>
