<template>
  <v-toolbar>
    <v-btn
      text
      rounded
      @click="$emit('close-filters')"
    >
      <v-icon left>
        {{ $vuetify.icons.values.close }}
      </v-icon>
      {{ $t('general.close') }}
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'SimpleSheetBottomToolbar'
}
</script>
